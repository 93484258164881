<template>
  <div>
    <template v-for="{ url, icon, name } in socials" :key="name">
      <NuxtLink
        :to="url"
        rel="me"
        target="_blank"
        :title="name"
        :external="true"
      >
        <Icon :name="icon" size="24" class="me-2" />
      </NuxtLink>
    </template>
  </div>
</template>

<script lang="ts" setup>
const { socials } = useAppConfig();
</script>
